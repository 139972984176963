import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const SuccessPageTemplate = ({ 
  title, 
  subtitle, 
  meta_title, 
  heading, 
  description, 
  meta_description, 
  faq 
}) => {
  return <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='hero is-medium'>
      <div className='hero-body pb-6'>
        <div className='columns column is-three-fifths is-offset-one-fifth'>

          <div className='column'>
            <div className='image is-3by4'>
              <iframe className='has-ratio' src="https://muse.ai/embed/NY7HWBc?search=0&links=0&logo=0" frameborder="0" allowfullscreen></iframe>
            </div>
          </div>

          <div className='column'>

            <h1 className='title is-size-1 has-text-weight-bold'>
              {title}
            </h1>

            <div className='box content'>

              <h4 className='has-text-weight-bold is-size-4'>I can't wait to explore what you've got going on!</h4>

              <p>Shortly I'll be following up with a few questions via email.</p>
              <p>Before I can do anything, you'll need to answer those and get me up to speed.</p>
              <p>They're designed to ensure I make the most out of my time with your URL + bring you the most possible.</p>

              <p>Speak soon, <a href="https://twitter.com/rufusdenne" title='Rufus'>Rufus</a></p>


            </div>  
          </div>

        </div>
      </div>
    </section>


  </div>
}

SuccessPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
}

export default SuccessPageTemplate
